import React from "react";

const DashboardContent = ({ cols, children }) => {
  return (
    <div className={`flex-grow grid gap-8 max-h-max grid-cols-${cols}`}>
      {children}
    </div>
  );
};

export default DashboardContent;
