import React, { useEffect, useCallback } from 'react';
import Dashboard from '../components/layout/Dashboard';
import DashboardContent from '../components/layout/DashboardContent';
import axios from 'axios';

const SupportUren = () => {
	const fetchData = useCallback(async () => {
		try {
			const response = await axios.post(
				`https://api.hubapi.com/crm/v3/objects/deals/search`,
				{
					properties: [
						'aantal_uren',
						'dealstage',
						'hubspot_owner_id',
					],
					filterGroups: [
						{
							filters: [
								{
									propertyName: 'dealstage',
									value: '487687672',
									operator: 'EQ',
								},
								{
									propertyName: 'closedate',
									value: '1719792000000',
									operator: 'GT',
								},
							],
						},
					],
				},
				{
					headers: {
						Authorization:
							'Bearer pat-eu1-53100465-c1d7-4e6d-a87f-4d6480a0a0fe',
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
					},
				}
			);
			console.log(response.data);
		} catch (error) {
			console.error('Error fetching deals data:', error);
		}
	}, []);

	useEffect(() => {
		// Fetch data initially
		fetchData();
	});

	return (
		<Dashboard pageName="Support Uren">
			<DashboardContent></DashboardContent>
		</Dashboard>
	);
};

export default SupportUren;
