import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_BASE,
});

console.log('Axios Base URL:', process.env.REACT_APP_BACKEND_BASE);


export default axiosInstance;
