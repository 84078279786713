import React from "react";
import Dashboard from "../components/layout/Dashboard";
import DashboardContent from "../components/layout/DashboardContent";
import Card from '../components/common/Card';

export default function Root() {
  return (
    <Dashboard pageName="Fullservice WordPress bureau">
      <DashboardContent cols="4">
        <Card title="Ahoy!">
					<ul className="flex flex-col gap-5">
						<li><a href="/ruimtes" className="bg-strl-zb text-white py-2 px-4 leading-4 rounded-md">Vergaderruimtes</a></li>
						<li><a href="/support" className="bg-strl-zb text-white py-2 px-4 leading-4 rounded-md">Support</a></li>
					</ul>
        </Card>
      </DashboardContent>
    </Dashboard>
  );
}
