import React from 'react';

const Card = ({ title, children }) => {
	return (
		<div className='px-12 py-10 bg-strl-wz border-b-2 border-strl-zb-40'>
			{title && <h2 className='text-4xl text-strl-zb font-medium mb-4'>{title}</h2>}
			<div>{children}</div>
		</div>
	);
};

export default Card;
