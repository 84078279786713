// RoomSchedule.js

import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import "moment/locale/nl";

const RoomSchedule = ({ title, roomId }) => {
  const [events, setEvents] = useState([]);
  const [isAvailable, setIsAvailable] = useState(true);
  const [statusMessage, setStatusMessage] = useState("Beschikbaar");
  const [error, setError] = useState(null);

  const checkAvailability = (events) => {
    const now = moment();
    const isRoomAvailable = !events.some((event) => {
      const start = moment(event.start.dateTime);
      const end = moment(event.end.dateTime);
      return now.isBetween(start, end);
    });

    if (isRoomAvailable) {
      const nextEvent = events.find((event) => {
        const start = moment(event.start.dateTime);
        return start.isAfter(now) && start.diff(now, "minutes") <= 15;
      });

      if (nextEvent) {
        const minutesUntilNextEvent = moment(nextEvent.start.dateTime).diff(
          now,
          "minutes"
        );
        setStatusMessage(`Nog ${minutesUntilNextEvent} minuten vrij`);
      } else {
        setStatusMessage("Beschikbaar");
      }
    } else {
      setStatusMessage("Bezet");
    }

    setIsAvailable(isRoomAvailable);
  };

  const fetchEvents = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_NODE_SERVER_HOST + `/api/rooms/${roomId}`
      );
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      const data = await response.json();
      if (data.events) {
        setEvents(data.events);
        checkAvailability(data.events);
        setError(null); // Clear any previous error
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.message);
    }
  }, [roomId]);

  const getPhotoUrl = (email) => {
    try {
      return require(`../../../img/${email}.jpg`); // Adjust the path as needed
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    // Fetch data initially and check availability
    fetchEvents();

    // Set up the interval to fetch data every 30 seconds
    const interval = setInterval(() => {
      fetchEvents();
    }, 30000);

    // Clear the intervals on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [fetchEvents]);

  return (
    <div className="h-full">
      {title && (
        <div className="flex justify-between flex-row mb-4">
          <h2 className="text-2xl font-serif text-strl-zb font-medium">
            {title}
          </h2>
          <span
            className={`self-center text-sm px-2 py-1 rounded-md ${
              statusMessage.includes("Nog")
                ? "bg-strl-dl text-strl-zb"
                : isAvailable
                ? "bg-strl-zg text-strl-zb"
                : "bg-strl-k text-strl-zb"
            }`}
          >
            {statusMessage}
          </span>
        </div>
      )}
      <ul className="flex gap-4 flex-col h-full">
        {error
          ? `Loading error: ${error}`
          : !events.length
          ? "Loading..."
          : events.map((event) => {
              const now = moment();
              const start = moment(event.start.dateTime);
              const end = moment(event.end.dateTime);
              const isCurrentEvent = now.isBetween(start, end);
              const startTime = start.locale("nl").format("LT"); // Format the start time
              const endTime = end.locale("nl").format("LT"); // Format the end time

              let attendees = [event.creator.email];
              if (event.attendees && event.attendees.length) {
                attendees = attendees.concat(
                  event.attendees.map((attendee) => attendee.email)
                );
              }

              attendees = [...new Set(attendees)];

              const attendeePhotoUrls = attendees
                .map((email) => getPhotoUrl(email))
                .filter((photoUrl) => photoUrl !== null); // Filter out null photo URLs

              return (
                <li
                  key={event.id}
                  className={`px-6 py-5 border-b-2 border-strl-zb-40 ${
                    isCurrentEvent ? "bg-strl-dl-40" : "bg-strl-wz"
                  }`}
                >
                  <div className="flex flex-row gap-2 items-center mb-2">
                    <span
                      className={` ${
                        isCurrentEvent ? "text-strl-zb" : "text-strl-zb-40"
                      }`}
                    >
                      {isCurrentEvent ? (
                        <div className="">
                          <span className="bg-strl-k leading-5 px-2 text-strl-zb mr-1 rounded-md text-sm">
                            Nu
                          </span>
                          <span className="text-s">tot {endTime}</span>
                        </div>
                      ) : (
                        <span className="border rounded-md border-strl-zb text-sm py-1 px-2 text-strl-zb">
                          {startTime} - {endTime}
                        </span>
                      )}
                    </span>
                    <div className="-space-x-3">
                      {attendeePhotoUrls.slice(0, 5).map((photoUrl, index) => {
                        const zIndex = 200 - index * 10;
                        return (
                          <img
                            key={index}
                            src={photoUrl}
                            alt={`attendee-${index}`}
                            style={{ zIndex }} // Apply zIndex directly via style prop
                            className="relative inline object-cover object-top w-7 aspect-square border-2 border-white rounded-full"
                          />
                        );
                      })}
                      {attendeePhotoUrls.length > 5 && (
                        <div
                          style={{ zIndex: 200 - 5 * 10 }} // Adjust zIndex for the +X circle
                          className="relative text-xs pl-4 inline-flex font-medium"
                        >
                          +{attendeePhotoUrls.length - 5}
                        </div>
                      )}
                    </div>
                    {!event.conferenceData ? (
                      ""
                    ) : (
                      <img
                        src={event.conferenceData.conferenceSolution.iconUri}
                        className="w-4 ml-auto"
                        alt=""
                      />
                    )}
                  </div>
                  <h3 className="text-lg text-strl-zb leading-6 line-clamp-2">
                    {event.summary}
                  </h3>
                </li>
              );
            })}
      </ul>
    </div>
  );
};

export default RoomSchedule;
