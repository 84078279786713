import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './pages/Root';
import Support from './pages/Support';
import SupportUren from './pages/SupportUren';
// import Sales from './pages/Sales';
import ErrorPage from './pages/ErrorPage';
import Rooms from './pages/Rooms';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/support',
		element: <Support />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/support/uren',
		element: <SupportUren />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/ruimtes',
		element: <Rooms />,
		errorElement: <ErrorPage />,
	},
	// {
	// 	path: '/sales',
	// 	element: <Sales />, // Add the Sales route
	// 	errorElement: <ErrorPage />,
	// },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
