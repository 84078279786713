import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/nl'; // Import the Dutch locale

export const DateTime = () => {
  const [dateTime, setDateTime] = useState(moment());

  useEffect(() => {
		moment.locale('nl');
    const intervalId = setInterval(() => {
      setDateTime(moment());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='flex flex-col items-end'>
      <span>{dateTime.format('HH:mm')}</span>
      <span className='font-normal text-lg leading-none lowercase'>
        {dateTime.format('D MMMM Y')}
      </span>
    </div>
  );
};

export default DateTime;
