import DateTime from '../../utils/dateTime';

export default function Footer({pageName, footerText}) {
	return (
		<div className="flex w-full justify-between text-strl-zb font-medium leading-none text-2xl items-end gap-4">
			<div className="flex flex-col w-64">
				<span className="font-bold ">stuurlui</span>
				<span className="font-normal text-lg leading-none lowercase">{pageName}</span>
			</div>
			<span className="font-light text-base text-strl-zb-40 leading-none lowercase">{footerText}</span>
			<div className="w-64">
				<DateTime></DateTime>
			</div>
		</div>
	);
}
