import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../axiosInstance';
import Dashboard from '../components/layout/Dashboard';
import DashboardContent from '../components/layout/DashboardContent';
import Card from '../components/common/Card';

const pipelines = [
	{
		id: '0',
		title: 'Support Tickets',
		stages: [
			{ id: '1', name: 'Nieuw' },
			{ id: '3', name: 'Wachtend op ons' },
			{ id: '2', name: 'Wachtend op contactpersoon' },
			{ id: '475648989', name: 'Ticket Support Dev' },
			{ id: '626432708', name: 'In Ontwikkeling' },
		],
	},
	{
		id: '177690574',
		title: 'Devops tickets',
		stages: [
			{ id: '322526919', name: 'Nieuw' },
			{ id: '322526921', name: 'Nog op te pakken' },
			{ id: '401244093', name: 'Mee bezig' },
			{
				id: '401244092',
				name: 'Meer informatie nodig',
			},
			// { id: '487524286', name: 'Klein taken' },
			{
				id: '322526920',
				name: 'Wachtend op contactpersoon',
			},
		],
	},
	{
		id: '177690575',
		title: 'Support developer',
		stages: [
			{ id: '322526923', name: 'Open' },
			{ id: '621479153', name: 'Hoge prioriteit' },
			{ id: '322526924', name: 'Wordt opgepakt' },
			{ id: '322526925', name: 'Testen' },
			{ id: '322526927', name: 'Extra actie vereist' },
			{ id: '621479154', name: 'Mag live' },
		],
	},
	// {
	// 	id: '251158264',
	// 	title: 'Finance',
	// 	stages: [
	// 		{ id: '419008744', name: 'Nieuw' },
	// 		{ id: '419008745', name: 'Wachtend op contactpersoon' },
	// 		{ id: '503130324', name: 'In afwachting van betaling' },
	// 		{ id: '419008746', name: 'Wachtend op ons' },
	// 	],
	// },
];

const Support = () => {
	const [ticketData, setTicketData] = useState({});
	const [countdown, setCountdown] = useState(30);

	const fetchData = useCallback(async () => {
		const data = {};
		try {
			for (const pipeline of pipelines) {
				const response = await axiosInstance.get(
					`/api/tickets/stats/${pipeline.id}`
				);
				const pipelineData = response.data;
				data[pipeline.id] = {};

				for (const stage of pipeline.stages) {
					data[pipeline.id][stage.id] = pipelineData[stage.id] || 0;
				}
			}
			setTicketData(data);
		} catch (error) {
			console.error('Error fetching ticket data:', error);
		}
	}, []);

	useEffect(() => {
		// Fetch data initially
		fetchData();

		// Set up the interval to fetch data every 30 seconds
		const interval = setInterval(() => {
			fetchData();
			setCountdown(30);
		}, 30000);

		// Countdown logic
		const countdownInterval = setInterval(() => {
			setCountdown((prevCountdown) =>
				prevCountdown > 0 ? prevCountdown - 1 : 30
			);
		}, 1000);

		// Clear the intervals on component unmount
		return () => {
			clearInterval(interval);
			clearInterval(countdownInterval);
		};
	}, [fetchData]);

	return (
		<Dashboard
			pageName="Support"
			footerText={`Update over ${countdown} seconden`}
		>
			<DashboardContent cols={4}>
				{pipelines.map((pipeline) => (
					<div key={pipeline.id}>
						<Card title={pipeline.title}>
							<ul>
								{pipeline.stages.map((stage) => (
									<li
										key={stage.id}
										className="border-b-[1px] border-strl-zb-20 last:border-b-0 py-4 text-strl-zb flex justify-between align-middle"
									>
										<span className="font-medium self-center text-xl">
											{stage.name || 'Onbekend'}
										</span>
										<span className="label">
											{ticketData[pipeline.id]?.[
												stage.id
											] ?? 0}
										</span>
									</li>
								))}
							</ul>
						</Card>
					</div>
				))}
				{/* <div className="col-span-2">
					<Card title="Nieuwe support tickets in de afgelopen 7 dagen">
						<SupportChart />
					</Card>
				</div> */}
			</DashboardContent>
		</Dashboard>
	);
};

export default Support;
