import React from "react";
import Dashboard from "../components/layout/Dashboard";
import DashboardContent from "../components/layout/DashboardContent";
import RoomSchedule from "../components/common/rooms/RoomSchedule";

const Rooms = () => {
  return (
    <Dashboard pageName="vergaderruimtes">
      <DashboardContent cols="4">
        <RoomSchedule title="Stuurboard" roomId="sander@stuurlui.nl" />
        <RoomSchedule
          title="Grote ruimte"
          roomId="stuurlui.nl_36333530303336313636@resource.calendar.google.com"
        />
        <RoomSchedule
          title="Kleine ruimte"
          roomId="c_1888e48n9b3hkhl0mr2qc2keo4h12@resource.calendar.google.com"
        />
        <RoomSchedule
          title="Test ruimte"
          roomId="c_08f6b9c89ff815a28f7cb73d6d8c7a27046e874ea479160de468968319043508@group.calendar.google.com"
        />
      </DashboardContent>
    </Dashboard>
  );
};

export default Rooms;
