import React from 'react';
import Footer from '../common/Footer';

const Dashboard = ({ children, pageName, footerText }) => {
  return (
    <div className="flex flex-col h-screen p-10 gap-8">
      <div className="flex-grow max-h-full overflow-hidden">
        {children}
      </div>
      <Footer pageName={pageName} footerText={footerText} />
    </div>
  );
};

export default Dashboard;
